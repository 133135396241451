var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"routes"}},[_vm._m(0),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-card',[_c('v-row',{staticClass:"px-2 pt-4 pb-0 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"label":"Route ID","outlined":"","hide-details":"","dense":""},on:{"change":function (val) { return _vm.addToSearchHistory(val, 'sqOnID'); }},model:{value:(_vm.sqOnID),callback:function ($$v) {_vm.sqOnID=$$v},expression:"sqOnID"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"label":"Route code","outlined":"","hide-details":"","dense":""},on:{"change":function (val) { return _vm.addToSearchHistory(val, 'sqOnShortName'); }},model:{value:(_vm.sqOnShortName),callback:function ($$v) {_vm.sqOnShortName=$$v},expression:"sqOnShortName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"label":"Route name","outlined":"","hide-details":"","dense":""},on:{"change":function (val) { return _vm.addToSearchHistory(val, 'sqOnLongName'); }},model:{value:(_vm.sqOnLongName),callback:function ($$v) {_vm.sqOnLongName=$$v},expression:"sqOnLongName"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.filteredRoutes,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"custom-sort":_vm.customSort,"page":_vm.page,"must-sort":true},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.route_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"cursor-pointer text-decoration-none",attrs:{"to":{ name: 'next-stop-route-config', params: { id: item.route_id } }}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.route_id))])])])])]}},{key:"item.route_short_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"cursor-pointer text-decoration-none",attrs:{"to":{ name: 'next-stop-route-config', params: { id: item.route_id } }}},[_c('h2',{staticClass:"text-sm font-weight-bold mb-1 mt-1"},[_c('route-badge',{attrs:{"route":item,"size":38,"avatar-class":"vehicle-avatar","text-class":"text-1xl"}})],1)])]}},{key:"item.route_long_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"cursor-pointer text-decoration-none",attrs:{"to":{ name: 'next-stop-route-config', params: { id: item.route_id } }}},[_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.route_long_name))])])]}},{key:"item.route_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_c('v-tooltip',{staticClass:"text--primary",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.routeTypePresentation(item.route_type).icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.routeTypePresentation(item.route_type).text))])])],1)]}},{key:"item.agency_id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.agency_id))])]}},{key:"item.config",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ name: 'next-stop-route-config', params: { id: item.route_id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSquareEditOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Edit configuration for route "),_c('span',{staticClass:"route_presentation",style:({ color: item.route_text_color, backgroundColor: item.route_color })},[_vm._v(" "+_vm._s(item.route_short_name)+" ")])])])],1)]}}],null,true)}),_c('CSVExportButton',{attrs:{"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items":_vm.routes,"column-map":_vm.routeColumnMap}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title px-4"},[_c('h2',[_c('span',{staticClass:"me-2"},[_vm._v("Route Management")])])])}]

export { render, staticRenderFns }